@font-face {
  font-family: 'Good Sans';
  src: url('../assets/fonts/Good\ Sans/GoodSans-Regular.woff');
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'Good Sans';
  src: url('../assets/fonts/Good\ Sans/GoodSans-Medium.woff');
  font-weight: 500;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'TT Commons Pro';
  src: url('../assets/fonts/TT\ Commons\ Pro/TT\ Commons\ Pro\ Normal.otf');
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'TT Commons Pro';
  src: url('../assets/fonts/TT\ Commons\ Pro/TT\ Commons\ Pro\ Medium.otf');
  font-weight: 500;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'TT Commons Pro';
  src: url('../assets/fonts/TT\ Commons\ Pro/TT\ Commons\ Pro\ DemiBold.otf');
  font-weight: 600;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'TT Commons Pro';
  src: url('../assets/fonts/TT\ Commons\ Pro/TT\ Commons\ Pro\ Bold.otf');
  font-weight: 700;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'La Belle Aurore';
  src: url('../assets/fonts/La\ Belle\ Aurore/LaBelleAurore-Regular.ttf');
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}
