:root {
  /* Primary */
  --primary-maroon: #800532;
  --primary-black: #230603;
  --primary-parchment: #faf3ec;
  --primary-white: #fff;

  /* Secondary */
  --secondary-dark-cream: #f4efec;
  --secondary-light-cream: #fbf7f5;
  --secondary-pale-maroon: #f2e6ea;
  --secondary-dark-maroon: #600426;

  /* Utility */
  --utility-50: #e9eaeb;
  --utility-100: #fefefe;
  --utility-200: #fcfcfc;
  --utility-250: #f9f9f9;
  --utility-275: #f3f3f3;
  --utility-300: #f1f1f1;
  --utility-400: #e0e0e0;
  --utility-500: #c9c9c9;
  --utility-600: #adadad;
  --utility-700: #8d8d8d;
  --utility-800: #6b6b6b;
  --utility-900: #494949;
  --utility-1000: #272727;
  --utility-1100: #0f0f0f;
  --utility-yellow: #d58d49;
  --utility-yellow-1: #f8e8ca;
  --utility-yellow-2: #e9a10c;
  --utility-yellow-2-new: #f2d192;
  --utility-yellow-3: #fef7ea;
  --utility-yellow-3-new: #d49b2e;
  --utility-yellow-4: #fce8c0;
  --utility-yellow-4-new: #9d762e;
  --utility-yellow-5: #eea40d;
  --utility-yellow-6: #f4ad1b;
  --utility-yellow-7: #e6a976;
  --utility-yellow-8: #ce8c53;
  --utility-light-yellow: #f9eee3;
  --utility-dark-yellow: #9c5f24;
  --utility-green: #1dc00f;
  --utility-green-1: #e0e4ca;
  --utility-green-2: #adbb96;
  --utility-green-3: #e0e4ca;
  --utility-green-3-new: #8aa165;
  --utility-green-4: #888569;
  --utility-light-green: #d2f2cf;
  --utility-dark-green: #127809;
  --utility-red: #f00;
  --utility-light-red: #f39e9e;
  --utility-red-1: #b00000;
  --utility-red-4: #f8c2c2;
  --utility-orange: #ff5c00;
  --utility-light-orange: #ffeee4;
  --utility-orange-500: #e9a10c;
  --utility-orange-1: #f6e5d8;
  --utility-orange-3: #d38543;
  --utility-orange-2: #f0b786;
  --utility-orange-4: #936e54;
  --utility-cultured: #f5f7fa;
  --utility-light-cultured: #f5f5f7;
  --utility-blue: #4597f7;
  --utility-blue-1: #275cac;
  --utility-blue-1-new: #d9e3f3;
  --utility-blue-2: #eef1f6;
  --utility-blue-3: #f6f9ff;
  --utility-blue-3-new: #7394c5;
  --utility-blue-4: #a0b5d5;
  --utility-pink-1: #f6e1e6;
  --utility-pink-2: #e0baba;
  --utility-pink-3: #c78181;
  --utility-pink-4: #886969;
  --utility-purple-1: #e6dded;
  --utility-purple-2: #baa1cd;
  --utility-purple-3: #8d70a3;
  --utility-purple-4: #726e87;
  --utility-purple-5: #88709b;
  --utility-eggplant: #803a99;
  --utility-blueberry: #697b88;
  --utility-blueberry-light: #e1e5e7;
  --utility-grape: #726e87;
  --utility-grape-light: #e3e2e7;
  --utility-olive: #888569;
  --utility-olive-light: #e7e7e0;
  --utility-pale-olive: #adbb96;
  --utility-black-currant: #242020;
  --utility-tamarind: #936e54;
  --utility-tamarind-light: #e9e2dd;
  --utility-turnip: #886969;
  --utility-turnip-light: #e7e1e1;
  --utility-chard: #698884;
  --utility-chard-light: #e1e7e6;
  --utility-highlight-opacity: rgb(35 6 3 / 5%);
  --utility-teal-1: #cde2df;
  --utility-teal-2: #99bfba;
  --utility-teal-3: #6fa49d;
  --utility-teal-4: #698884;
}
