@import url('./root.css');
@import url('./fonts.css');

* {
  box-sizing: border-box;
  font-family: 'TT Commons Pro', sans-serif;
  scroll-behavior: smooth;
}

body {
  font-family: 'TT Commons Pro', sans-serif;
  color: var(--primary-black);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  position: relative;
  z-index: 0;
}

code {
  padding: 0.125rem 0.25rem;
  background-color: var(--utility-400);
  border-radius: 0.125rem;
  font-family: Consolas, monospace;
  font-size: 80%;
}

button {
  outline: none;
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

/* unvisited link */
a:link {
  text-decoration: none;
  color: inherit;
}

/* visited link */
a:visited {
  text-decoration: none;
  color: inherit;
}

/* mouse over link */
a:hover {
  text-decoration: none;
  color: inherit;
}

/* selected link */
a:active {
  text-decoration: none;
  color: inherit;
}

/* For tiptap rich text editor */

.ProseMirror {
  position: relative;
  min-height: inherit;
}

.ProseMirror:focus {
  outline: none;
}

.with-border > .ProseMirror::after {
  content: '';
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
  width: calc(100% + 1rem);
  height: calc(100% + 1rem);
  border: 1px solid var(--primary-maroon);
  border-radius: 0.25rem;
  pointer-events: none;
  transition: all 0.2s;
  opacity: 0;
}

.with-border > .ProseMirror:focus::after {
  opacity: 1;
}

.ProseMirror *.is-empty:first-child::before {
  color: var(--utility-500);
  content: attr(data-placeholder);
  float: left;
  min-height: 100%;
  pointer-events: none;
  padding: 0;
  margin: 0;
  display: block;
  width: 100%;
  position: absolute;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 600;
}

/* Inside a block wrapper */
.ProseMirror *.block.is-empty:first-child::before {
  padding: 4px 0;
}

.ProseMirror *.is-empty::before {
  display: none;
}

/* ---- commandMenu extension ---- */
.ProseMirror .commandMenu-suggestion {
  display: inline-block;
}

.ProseMirror .is-commandMenu-empty::after {
  content: attr(data-commandMenuPlaceholder);
  pointer-events: none;
  padding: 4px 8px;
  margin-left: 8px;
  display: inline;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
}

/* ---- end of commandMenu extension ---- */

/* ---- dropcursor extension ---- */

/* .drop-cursor {
  border-radius: 40px;
} */

/* ---- dropcursor extension ---- */

/* ---- column extension ---- */
.ProseMirror .columnList {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}

.ProseMirror .column {
  transition-property: width;
  transition-duration: 200ms;
  transition-timing-function: ease;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}

/* ---- end of column extension ---- */

/* styles for task list and task item in juicebox note rich text editor */
.NoteRichTextEditor ul[data-type='taskList'] {
  list-style: none;
  padding: 0;
}

.NoteRichTextEditor ul[data-type='taskList'] p {
  margin: 0;
}

.NoteRichTextEditor ul[data-type='taskList'] li {
  display: flex;
}

.NoteRichTextEditor ul[data-type='taskList'] li > label {
  flex: 0 0 auto;
  margin-right: 0.5rem;
  user-select: none;
}

.NoteRichTextEditor ul[data-type='taskList'] li > div {
  flex: 1 1 auto;
}

.NoteRichTextEditor ul[data-type='taskList'] li ul li,
.NoteRichTextEditor ul[data-type='taskList'] li ol li {
  display: list-item;
}

.NoteRichTextEditor ul[data-type='taskList'] li ul[data-type='taskList'] > li {
  display: flex;
}

.NoteRichTextEditor .node-taskItem.has-focus .task-action {
  visibility: visible;
}

.NoteRichTextEditor .bubble-menu-container {
  min-width: 700px;
  display: flex;
  pointer-events: none;
}

.NoteRichTextEditor .bubble-menu-container.visible {
  pointer-events: auto;
}

/* Give a remote user a caret */
.collaboration-cursor__caret {
  border-left: 1px solid var(--primary-black);
  margin-left: -1px;
  margin-right: -1px;
  pointer-events: none;
  position: absolute;
  word-break: normal;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  font-style: normal;
  font-weight: 600;
  left: -1px;
  line-height: normal;
  position: relative;
  user-select: none;
  white-space: nowrap;
  font-size: 14px;
  color: var(--primary-white);
  top: -1.4em;
  border-radius: 6px 6px 6px 0;
  padding: 2px 6px;
  pointer-events: none;
  z-index: 1000;
}

/* Hide videoask widget button on default */
[class*='videoask-embed__button'] {
  display: none;
}

/* Tiptap - Block Wrapper */
.block {
  position: relative;
  margin: 0;
  padding: 0;
  transition: width 0.2s;
}

.block[data-block-id]::after {
  content: '';
  display: block;
  border-radius: 4px;
  width: calc(100% + 4px);
  height: 100%;
  position: absolute;
  top: 0;
  left: -2px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;
}

.block[data-block-id].dragged-over::after,
.block[data-block-id].has-focus:not(.node-paragraph, .node-codeBlock)::after {
  opacity: 1;
  background-color: rgb(0 0 0 / 5%);
}

.block > * {
  margin: 0;
}

/* --- */

.block.node-paragraph {
  margin: 2px 0;
}

.block.node-paragraph p {
  padding: 4px 0;
}

/* --- */

.block ul,
.block ol {
  padding-inline-start: 24px;
}

.block li {
  padding-left: 4px;
}

/* --- */

.block.node-embed {
  margin: 4px auto;
}

.block.node-embed .embed-node-view {
  position: relative;
}

.block.node-embed .embed-node-view::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: difference;
  pointer-events: none;
  background-color: transparent;
  transition: background-color 0.2s;
}

.block.node-embed .embed-node-view:hover::after {
  background-color: rgb(255 255 255 / 5%);
}

/* HACK. Embed content (iframe, etc.) seems to be messing with mouse dragging events */
.ProseMirror.is-dragging .block.node-embed {
  pointer-events: none;
}

/* --- */

.block.node-heading {
  margin-top: 16px;
  margin-bottom: 2px;
}

/* --- */

.block.node-horizontalRule::before {
  content: '';
  display: block;
  position: absolute;
  top: 16px;
  width: 100%;
  height: 2px;
}

.block.node-horizontalRule > hr {
  padding: 16px 0;
  border: 0;
}

/* --- */

.block.node-blockquote {
  padding: 0 16px;
  margin: 16px 0;
  border-left: 4px solid var(--utility-400);
}

.block.node-blockquote .block.node-blockquote {
  margin-bottom: 0;
}

/* --- */

.ProseMirror a:link {
  text-decoration: underline;
}

/* --- Animations --- */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
